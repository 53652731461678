import { getFromCookie } from './utils';

const getFromLocalstorage = () =>
  [...Array(localStorage.length).keys()].reduce((map, i) => {
    map[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
    return map;
  }, {});

const getAuthData = authDataKey => {
  const accountType = getFromCookie(authDataKey, 'accountType') || '';
  const dom = getFromCookie(authDataKey, 'dom') || '';
  const hasGov = getFromCookie(authDataKey, 'hasGov') || false;
  const loginType = `${hasGov ? 'gov' : ''}`;

  return { accountType, dom, loginType };
};

const getUrl = (check, versionSufix) =>
  check
    ? `${window.apw.APW_CONFIG_URL + window.apw.APW_ENV}-${window.apw.APW_ROOT_RULE}${versionSufix}`
    : 'https://ruller.apps.hm.bb.com.br/rules/hml-showcase-config';

const sendRequest = (url, state, accountType, loginType, dom) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onerror = () => {
      reject(xhr.status);
    };
    xhr.onload = () => {
      const { status } = xhr;
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(status);
      }
    };
    xhr.send(
      JSON.stringify({
        apw_state: state,
        apw_account_type: accountType,
        login_type: loginType,
        dom_account: dom,
      }),
    );
  });

export const getConfig = () => {
  const data = getFromLocalstorage();
  let state = 'DF';
  const authDataKey = window.apw.APW_AUTH_DATA_KEY || 'APW_OAUTH_USER_DATA';
  const { accountType, dom, loginType } = getAuthData(authDataKey);

  if ('apw_state' in data) state = data.apw_state;

  const check = window.apw.APW_CONFIG_URL && window.apw.APW_ENV && window.apw.APW_ROOT_RULE;
  const versionSufix = window.apw.APW_RULE_VERSION ? `/${window.apw.APW_RULE_VERSION}` : '';
  const url = getUrl(check, versionSufix);

  if (data.apw_mod_start === 'local' && 'apw_local_config' in data) {
    return Promise.resolve(JSON.parse(data.apw_local_config));
  }

  return sendRequest(url, state, accountType, loginType, dom);
};
